import { IAuxiliaryGameDataReplyData } from 'server/lib-rpc';
import { sdk } from 'server/sdk';
import { IPlayData, ITableData } from '@sandsb2b/areax-client-library-rpc/dist/client';
import { formatCurrency } from '../../../common/shared';
import { IGameData } from '../../../lib';
import { GameTypeRegistry } from './GameTypeRegistry';

const combineLocalAndServerTableData = async (
	gameConfigData: IGameData,
	tableData: ITableData,
	playerData: number,
	playData?: Maybe<IPlayData>
): Promise<IGameData> => {
	const currencyCode = tableData.playConfig.currency;
	const tableMin = Number(tableData.playConfig.tableMin) / 100;
	const tableMax = Number(tableData.playConfig.tableMax) / 100;
	const hasLimits = tableMin + tableMax > 0;
	const playerCount = Number(playerData);

	let gameState: PlainObject = {};
	let gameStatistics: PlainObject[] = [];

	if (playData && playData.gameState) {
		try {
			gameState = playData.gameState.toJson({ typeRegistry: GameTypeRegistry }) as PlainObject;

			const fullData = {
				tableData: tableData,
				playData: playData,
				gameState: gameState,
			};

			console.log(`GAME STATE (${tableData.gameName}): `, fullData);

			gameStatistics = await sortGameStatistics(tableData.tableId, tableData.gameName, gameState);
		} catch (e) {
			console.warn('Failed to parse json', e);
		}
	}

	const gameData: IGameData = {
		...gameConfigData,
		name: tableData.gameName,
		tableNum: tableData.tableNum.toString(),
		tableMin: formatCurrency(tableMin, { currencyCode }),
		tableMax: formatCurrency(tableMax, { currencyCode }),
		hasLimits: hasLimits,
		players: playerCount,
		seatCount: Number(tableData.seatCount),
		seats: tableData.seats,
		lastUpdatedTs: Date.now(),
		gameStatistics: gameStatistics,
	};

	return gameData;
};

const sortGameStatistics = async (tableId, gameName, gameState): Promise<PlainObject[]> => {
	switch (gameName) {
		case 'baccarat':
			//HERE for Displaying UI when data isn't available
			// return await getRoadsData('c683e6de-24e4-4f8b-bdc6-b2523ac482fe', 'com.sands.ldx.games.Baccarat.RoadsMap');
			return await getRoadsData(gameState.roadsDataKey, gameState.roadsDataType);
		case 'dragon_tiger':
			//HERE for Displaying UI when data isn't available
			// return await getRoadsData('c683e6de-24e4-4f8b-bdc6-b2523ac482fe', 'com.sands.ldx.games.DragonTiger.RoadsMap');
			return await getRoadsData(gameState.roadsDataKey, gameState.roadsDataType);
		case 'roulette_chateau':
			return await getRouletteStatistics(tableId, 'com.sands.ldx.games.Roulette.Stats');
		case 'roulette_american':
			return await getRouletteStatistics(tableId, 'com.sands.ldx.games.Roulette.Stats');
		case 'jargon_ball':
		case 'blackjack_fastaction':
		case 'blackjack_multiseat':
			return [];
	}
};

const getRouletteStatistics = async (tableId, type) => {
	const gService = sdk.services.gameService;
	const resp: IAuxiliaryGameDataReplyData = await gService.getAuxiliaryGameData(tableId, type).promise;

	const jsonStr: string = new TextDecoder().decode(resp.data);
	const rouletteStats = JSON.parse(jsonStr);
	return Array.isArray(rouletteStats.history) ? rouletteStats.history : [rouletteStats.history];
};

const getRoadsData = async (roadsKey: string, roadsType: string) => {
	const gService = sdk.services.gameService;
	const resp: IAuxiliaryGameDataReplyData = await gService.getAuxiliaryGameData(roadsKey, roadsType).promise;
	const jsonStr: string = new TextDecoder().decode(resp.data);
	const roadsData = JSON.parse(jsonStr);
	return Array.isArray(roadsData.bead_plate.History) ? roadsData.bead_plate.History : [roadsData.bead_plate.History];
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { combineLocalAndServerTableData };

import { IPinnedTables } from '../../types';

class PinnedTables implements IPinnedTables {
	public static StorageKey: string = 'sds.lobby.internal.PinnedTables';

	/* #region ---- Public Methods ---------------------------------------------------------------------------------- */

	public async pin(tableId: string): Promise<boolean> {
		return this.save(tableId);
	}

	public async unpin(tableId: string): Promise<boolean> {
		const current = this.fetch();
		const index = current.findIndex((tid: string) => tid === tableId);

		if (index > -1) {
			current.splice(index, 1);
			this.saveList(current);

			return true;
		}

		return false;
	}

	public async load(): Promise<string[]> {
		return this.fetch();
	}

	/* #endregion ---- Public Methods ------------------------------------------------------------------------------- */

	/* #region ---- Private Methods --------------------------------------------------------------------------------- */

	private save(tableId: string): boolean {
		const current = this.fetch();
		const index = current.findIndex((tid: string) => tid === tableId);

		if (index > -1) {
			throw new Error(`Table id ${tableId} already pinned`);
		}

		const newList = current.concat([tableId]);
		this.saveList(newList);

		return true;
	}

	private saveList(list: string[]) {
		const jsonData = JSON.stringify(list);

		globalThis.localStorage.setItem(PinnedTables.StorageKey, jsonData);
	}

	private fetch(): string[] {
		const data = globalThis.localStorage.getItem(PinnedTables.StorageKey) ?? '[]';

		try {
			return JSON.parse(data) as string[];
		} catch (e: unknown) {
			const err = e as Error;
			throw new Error(`Failed to load pinned tables: ${err.message}`);
		}
	}

	/* #endregion ---- Private Methods ------------------------------------------------------------------------------ */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { PinnedTables as PinnedTablesLocalStorageAdapter };

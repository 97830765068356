import { BaccaratPlayStore } from '@sandsb2b/areax-client-library-rpc/dist/store/games/Baccarat/PlayStore';
import { action, makeAutoObservable, observable, toJS } from 'helpers/mobx';
import { GameTypes, getTableIdsFromGamesData, IGameData } from '../../../lib';
import { IStoreBase } from '../../lib-rpc';
import { getSdk } from '../../sdk';
import { getStores } from '../setup';
import { PinnedTablesLocalStorageAdapter } from './adapters/localstorage/PinnedTables';
import { combineLocalAndServerTableData } from './mappers';
import { IGameTablesStoreData, IPinnedTables } from './types';

class GameTablesStore implements IStoreBase {
	/* #region ---- Properties ---------------------------------------------------------------------------------------*/

	/**
	 * Store data used for all games.
	 */
	protected _data: IGameTablesStoreData;

	/**
	 * Local game configuration data.
	 */
	protected _gameConfigData: IGameData[];

	public _pinnedTables!: IPinnedTables;

	/**
	 * When the data was last updated.
	 */
	protected _lastUpdatedTs: number = 0;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR --------------------------------------------------------------------------------------*/

	public constructor() {
		makeAutoObservable<
			this,
			'_data' | '_lastUpdatedTs' | '_gameConfigData' | 'updateGameData' | 'addGameData' | 'setPinnedTables'
		>(this, {
			_data: observable,
			_gameConfigData: observable,
			_lastUpdatedTs: observable,
			updateGameData: action,
			addGameData: action,
			setPinnedTables: action,
		});

		this._data = GameTablesStore.defaultStoreData();
		this._gameConfigData = [];
		this._pinnedTables = new PinnedTablesLocalStorageAdapter();
	}

	/* #endregion ---- CONSTRUCTOR -----------------------------------------------------------------------------------*/

	/* #region ---- Getters ------------------------------------------------------------------------------------------*/

	public get isMobXBound(): boolean {
		return true;
	}

	public get lastUpdatedTs(): number {
		return this._lastUpdatedTs;
	}

	public get gamesList(): IGameData[] {
		return this._data.games;
	}

	public get gameShowsList(): IGameData[] {
		return this.gamesList.filter((entry) => entry.category === GameTypes.GAME_SHOW && entry.showGame === true);
	}

	public get tableGamesList(): IGameData[] {
		return this.gamesList.filter((entry) => entry.category === GameTypes.TABLE_GAME && entry.showGame === true);
	}

	public set gameConfigData(value: IGameData[]) {
		this.setGameConfigData(value);
	}

	public get pinnedTables(): string[] {
		return this._data.pinnedTables;
	}

	/* #endregion ---- Getters ---------------------------------------------------------------------------------------*/

	/* #region ---- Actions ------------------------------------------------------------------------------------------*/

	public clear() {
		this._data.games = [];
		this.setLastUpdatedTs();
	}

	public getGameConfigData(tableId: string): Nullable<IGameData> {
		return this._gameConfigData.find((entry) => entry.tableId === tableId) ?? null;
	}

	public setGameConfigData(value: IGameData[]) {
		this._gameConfigData = value;
	}

	public isTablePinned(tableId: string): boolean {
		return this._data.pinnedTables.includes(tableId);
	}

	public async pinTable(tableId: string): Promise<boolean> {
		try {
			const success = await this._pinnedTables.pin(tableId);

			if (success) {
				const newList = await this._pinnedTables.load();
				this.setPinnedTables(newList);

				return true;
			}
		} catch (e: unknown) {
			const err = e as Error;
			console.warn(`Failed to pin table: ${err.message}`);
		}

		return false;
	}

	public async unpinTable(tableId: string): Promise<boolean> {
		try {
			const success = await this._pinnedTables.unpin(tableId);

			if (success) {
				const newList = await this._pinnedTables.load();
				this.setPinnedTables(newList);

				return true;
			}
		} catch (e: unknown) {
			const err = e as Error;
			console.warn(`Failed to unpin table: ${err.message}`);
		}

		return false;
	}

	/* #region ---- Actions ------------------------------------------------------------------------------------------*/

	/* #region ---- Data Request Actions -----------------------------------------------------------------------------*/

	public populate() {
		const tableIds = getTableIdsFromGamesData(this._gameConfigData);

		tableIds.forEach((tableId: string) => {
			this.fetchTableData(tableId);
		});

		this.loadPinnedTables();
		this.setLastUpdatedTs();
	}

	private async loadPinnedTables(): Promise<void> {
		const pins = await this._pinnedTables.load();
		this.setPinnedTables(pins);
	}

	protected fetchTableData = async (tableId: string): Promise<boolean> => {
		const sdk = getSdk();
		const index = this._data.games.findIndex((entry) => entry.tableId === tableId);
		const match = index > -1 ? this._data.games[index] : null;

		const tableRes = await sdk.services.gameService.getTables().promise;
		const matchedTable = tableRes.tables.find((table) => table.tableId === tableId);
		if (!matchedTable) {
			console.warn(`fetchTableData: table ${tableId} not found`);
			return false;
		}

		const playerCount = matchedTable ? Number(matchedTable.playerCount) : 0;

		// If it was last updated less than 2 minutes ago, skip it.
		if (match && match.lastUpdatedTs > Date.now() - 120000) {
			return;
		}

		const gameConfig = this.getGameConfigData(tableId);
		if (gameConfig === null) {
			console.warn(`fetchTableData: game config data for table ${tableId} not found`);

			return false;
		}

		const result = await sdk.services.gameService.getTable(tableId, { includePlayConfig: true, includePlay: true })
			.promise;
		const gameData = combineLocalAndServerTableData(gameConfig, result.table, playerCount, result.play);

		if (index > -1) {
			this.updateGameData(index, await gameData);
		} else {
			this.addGameData(await gameData);
		}

		return true;
	};

	/* #endregion ---- Data Request Actions --------------------------------------------------------------------------*/

	/* #region ---- Private Actions ----------------------------------------------------------------------------------*/

	private updateGameData(index: number, data: IGameData) {
		this._data.games[index] = data;
	}

	private addGameData(data: IGameData) {
		this._data.games.push(data);
	}

	private setPinnedTables(data: string[]) {
		this._data.pinnedTables = data;
	}

	private setLastUpdatedTs(ts?: Maybe<number>) {
		this._lastUpdatedTs = ts ?? Date.now();
	}

	/* #endregion ---- Private Actions -------------------------------------------------------------------------------*/

	/* #region ---- Static -------------------------------------------------------------------------------------------*/

	/**
	 * @returns The default game data.
	 */
	public static defaultStoreData = (): IGameTablesStoreData => ({
		games: [],
		pinnedTables: [],
	});

	/* #endregion ---- Static ----------------------------------------------------------------------------------------*/

	/* #region ---- Debug --------------------------------------------------------------------------------------------*/

	public toJson(): PlainObject {
		const result = {
			games: toJS(this._data.games),
			pins: toJS(this._data.pinnedTables),
			config: toJS(this._gameConfigData),
		};

		return result;
	}

	/* #endregion ---- Debug -----------------------------------------------------------------------------------------*/
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { GameTablesStore };

import {
	AmericanRouletteGameState,
	AmericanRoulettePlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/AmericanRoulette';
import {
	BaccaratGameState,
	BaccaratHand,
	BaccaratPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/Baccarat';
import {
	BigRedPokerGameState,
	BigRedPokerHand,
	BigRedPokerPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/BigRedPoker';
import {
	ChateauRouletteGameState,
	ChateauRoulettePlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/ChateauRoulette';
import {
	EuropeanRouletteGameState,
	EuropeanRoulettePlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/EuropeanRoulette';
import {
	FastActionBlackJackGameState,
	FastActionBlackJackHand,
	FastActionBlackJackPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/FastActionBlackJack';
import {
	FastActionDragonTigerGameState,
	FastActionDragonTigerHand,
	FastActionDragonTigerPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/FastActionDragonTiger';
import {
	FastActionRouletteBonus,
	FastActionRouletteGameState,
	FastActionRoulettePlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/FastActionRoulette';
import {
	JargonBallBlowerBall,
	JargonBallGameState,
	JargonBallPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/JargonBall';
import {
	MultiseatBlackJackGameState,
	MultiseatBlackJackHand,
	MultiseatBlackJackSeatState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/MultiseatBlackJack';
import {
	ParadiseStudGameState,
	ParadiseStudHand,
	ParadiseStudPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/ParadiseStud';
import { SicBoGameState, SicBoPlayerState } from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/SicBo';
import {
	ThreeCardHoldemGameState,
	ThreeCardHoldemHand,
	ThreeCardHoldemPlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/ThreeCardHoldem';
import {
	TripleZeroRouletteGameState,
	TripleZeroRoulettePlayerState,
} from '@sandsb2b/areax-client-library-rpc/dist/client/rpc/games/TripleZeroRoulette';
import { bufCreateRegistry, BufRegistry } from '../../lib-rpc';

const GameTypeRegistry: BufRegistry = bufCreateRegistry(
	AmericanRouletteGameState,
	AmericanRoulettePlayerState,
	BaccaratGameState,
	BaccaratHand,
	BaccaratPlayerState,
	BigRedPokerGameState,
	BigRedPokerHand,
	BigRedPokerPlayerState,
	ChateauRouletteGameState,
	ChateauRoulettePlayerState,
	EuropeanRouletteGameState,
	EuropeanRoulettePlayerState,
	FastActionBlackJackGameState,
	FastActionBlackJackHand,
	FastActionBlackJackPlayerState,
	FastActionDragonTigerGameState,
	FastActionDragonTigerHand,
	FastActionDragonTigerPlayerState,
	FastActionRouletteBonus,
	FastActionRouletteGameState,
	FastActionRoulettePlayerState,
	JargonBallBlowerBall,
	JargonBallGameState,
	JargonBallPlayerState,
	MultiseatBlackJackGameState,
	MultiseatBlackJackHand,
	MultiseatBlackJackSeatState,
	ParadiseStudGameState,
	ParadiseStudHand,
	ParadiseStudPlayerState,
	SicBoGameState,
	SicBoPlayerState,
	ThreeCardHoldemGameState,
	ThreeCardHoldemHand,
	ThreeCardHoldemPlayerState,
	TripleZeroRouletteGameState,
	TripleZeroRoulettePlayerState
);

// ---- Export --------------------------------------------------------------------------------------------------------

export { GameTypeRegistry };

import { IGameData } from './types.lobby';

/**
 * @returns An array of the table ids within the game data JSON file.
 */
const getTableIdsFromGamesData = (gameConfigData: IGameData[]): string[] => {
	return gameConfigData.filter((entry) => entry.showGame === true).map((entry) => entry.tableId);
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { getTableIdsFromGamesData };

/**********************************************************************************************************************
 * Initialization of the various stores used by the player client (via the SDK).
 *********************************************************************************************************************/
import { Config } from '../../helpers';
import { IGameData } from '../../lib';
import { getSdk } from '../sdk';
import { GameTablesStore } from './GameTablesStore';
import { ILobbyStores } from './types.stores';

// ---- Setup ---------------------------------------------------------------------------------------------------------

interface ISetupStoresOpts {
	isDebugEnabled?: Maybe<boolean>;
}

let gameTablesStore: GameTablesStore;

/**
 * Setup the stores used by the player client and binds them to MobX.
 *
 * @returns StoreManager instance containing the stores.
 */
const setupStores = (opts?: Maybe<ISetupStoresOpts>) => {
	const isDebugEnabled = opts?.isDebugEnabled;

	if (isDebugEnabled === true) {
		const sds = window.sds ?? {};
		sds.getStores = (): ILobbyStores => getStores();
	}
};

// ---- Utilities -----------------------------------------------------------------------------------------------------

const loadGameConfigJson = async (url: string): Promise<unknown> => {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			console.error(`Response status: ${response.status}`);
			return [];
		}

		return await response.json();
	} catch (e: unknown) {
		const err = e as Error;
		console.error(`Failed to load games config data ${url}`);
	}

	return null;
};

const loadGameConfig = async (env: string): Promise<unknown> => {
	return await loadGameConfigJson(`/data/games.${env}.json`);
};

/**
 * @returns Key-value object of all store instances used by the player client.
 */
const getStores = (): ILobbyStores => {
	const sdk = getSdk();

	if (gameTablesStore == null) {
		gameTablesStore = new GameTablesStore();

		const deployment = Config.deployment || Config.domainName.replace('.areax.app', '') || 'default';

		loadGameConfig(deployment).then((data) => {
			gameTablesStore.gameConfigData = data as IGameData[];
			gameTablesStore.populate();
		});
	}

	return {
		...sdk.stores.getStores(),
		gameTablesStore,
	};
};

/**
 * Clears all player client stores instances.
 */
const clearStores = (): void => {
	const sdk = getSdk();

	sdk.actions.store.clearAllStores();
};

// ---- Exports -----------------------------------------------------------------------------------------------------

export { clearStores, getStores, setupStores };

/* Keys here match what's defined in the JSON data */
enum GameTypes {
	ALL_GAMES = 'All',
	GAME_SHOW = 'Game Show',
	TABLE_GAME = 'Table Game',
}

const GameTypeLabels = Object.freeze({
	[GameTypes.ALL_GAMES]: 'All Games',
	[GameTypes.GAME_SHOW]: 'Game Shows',
	[GameTypes.TABLE_GAME]: 'Table Games',
});

// ---- Exports -------------------------------------------------------------------------------------------------------

export { GameTypes, GameTypeLabels };
